@tailwind base;
@tailwind components;
@tailwind utilities;

.blinking {
  @apply hover:animation-blink;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

/* Add custom animation utility to Tailwind */
@layer utilities {
  .animation-blink {
    animation: blink 0.5s step-end infinite;
  }
}
/* /Button.css / */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin 2s linear infinite;
}

.link-button {
  position: relative;
  display: inline-block;
}

.link-button::before,
.link-button::after {
  content: "";
  position: absolute;
  border: 4px solid white;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.link-button::before {
  width: 100%;
  height: 100%;
  border-color: rgba(255, 255, 255, 0.5);
  z-index: 0;
}

.link-button::after {
  width: calc(100% + 12px);
  height: calc(100% + 12px);
  border-color: rgba(255, 255, 255, 0.3);
  z-index: -1;
}

@layer components {
  @keyframes loading-keys-app-loading {
    0%,
    80%,
    100% {
      opacity: 0.75;
      box-shadow: 0 0 #076fe5;
      height: 32px;
    }

    40% {
      opacity: 1;
      box-shadow: 0 -8px #076fe5;
      height: 40px;
    }
  }

  .animate-loading {
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  }

  .delay-160 {
    animation-delay: 0.16s !important;
  }

  .delay-320 {
    animation-delay: 0.32s !important;
  }
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

/* Custom animations */
@layer utilities {
  .animate-jump7456 {
    animation: jump7456 0.5s linear infinite;
  }

  .animate-shadow324 {
    animation: shadow324 0.5s linear infinite;
  }
}
